.select-address-widget {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.address-card {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.address-info {
    margin-left: 10px;
}

.address-info p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.add-new-address-button {
    margin-top: 20px;
    padding: 10px;
    background-color: #fff;
    border: 2px solid #ff0000;
    color: #ff0000;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.add-new-address-button:hover {
    background-color: #ff0000;
    color: #fff;
} 