.text-widget {
    display: inline-block;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin: 8px 16px;
    max-width: 80%;
    padding: 12px 16px;
    
    div {
        color: #333;
        font-size: 14px;
        line-height: 1.5;
    }
}