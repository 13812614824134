@import "../../forms.scss";

.popup-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99 !important;
}

.overlay {
  background-color: #00000075;
  z-index: 3;
  width: 100%;
  height: 100vh;

  .popup-content {
    padding: 20px;
    position: absolute;
    // width: fit-content;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f7f7f7;
    box-shadow: 0px 5px 11px #00000029;

    .popup-content-inner {
      h2 {
        color: #43425d;
      }

      p {
        color: #a3a6b4;
      }

      .form-field {
        margin-top: 15px;

        input,
        select {
          width: 348px;
        }
      }
    }
  }
}

.fix-bottom-btn-cta {
  right: 0;
  bottom: 20px;
}