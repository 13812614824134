.applicable-coupon-listing-widget {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

h2 {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.coupon-list {
    border: 1px solid #4caf50;
    border-radius: 8px;
    padding: 10px;
    background-color: #e8f5e9;
}

.coupon-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.coupon-info {
    flex-grow: 1;
    margin-right: 10px;
}

.coupon-info p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.coupon-code {
    background-color: #388e3c;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
}

.tap-to-copy {
    font-size: 10px;
    color: #666;
} 