.chips-listing-widget {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
    gap: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.chip {
    max-width: 300px;
    padding: 12px 20px;
    background-color: #fff;
    border: 1px solid #0066FF;
    border-radius: 25px;
    color: #0066FF;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    align-self: flex-end;

    &:hover {
        background-color: #0066FF;
        color: #fff;
    }
}