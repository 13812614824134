.main-header {
  justify-content: space-between;
  box-shadow: 0px 2px 6px #0000000a;

  .header-left {
    .searchbar {
      .header-search {
        .input-field {
          border: none !important;
        }
      }
    }
  }

  .header-right-fix {
    .header-notification {
      border-right: 1px solid #ebebf2;
      margin-right: 15px;

      .bellicon {
        margin-right: 15px;
      }
    }

    .header-profile {
      position: relative;

      .logout {
        position: absolute;
        top: 35px;
        right: 40px;
        padding: 15px;
        background-color: #ffffff;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 35px -10px rgba(184, 184, 184, 1);
        -moz-box-shadow: 0px 0px 35px -10px rgba(184, 184, 184, 1);
        box-shadow: 0px 0px 35px -10px rgba(184, 184, 184, 1);

        .name-n-email {
          .firstletter {
            background-color: rgb(236, 27, 236);
            width: 40px;
            height: 40px;
            font-size: 20px;
            color: #ffffff;
            border-radius: 50%;
            margin-right: 10px;
            border: none;
          }

          .fullname {
            h3 {
              font-size: 16px;
              line-height: 16px;
              color: #3c3c3c;
            }

            .email {
              font-size: 12px;
              color: #979797;
            }
          }
        }

        .logoutBtn {
          font-size: 16px;
          margin-top: 15px;
          display: flex;

          svg {
            color: #3c3c3c;
            width: 50px;
            height: 30px;
          }

          h3 {
            color: #3c3c3c;
          }
        }
      }

      p {
        font-size: 12px;
      }

      .icon-down {
        margin: 0 15px;
      }
    }
  }
}

.subheader {
  box-shadow: 0px 2px 6px #0000000a;
}

.haderVarient2 {
  box-shadow: 0px 2px 6px #0000000a;

  .pagename-n-input {
    .pagename-sec {
      margin: 0 15px;
      position: relative;

      h2 {
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;

        &:hover+.onhoverName {
          display: block;
          position: absolute;
          padding: 10px;
          top: 30px;
          left: 0;
          background-color: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.1607843137);
          z-index: 1;
          white-space: nowrap;
        }
      }

      .onhoverName {
        display: none;
      }

      .p-subname {
        color: #959595;
      }
    }
  }

  .right-buttons {
    button {
      margin-right: 15px;
    }

    .cancel-btn {
      background-color: #989ba938;
      color: #454545;
    }

    .right-bordered-sec {
      width: 1px;
      height: 30px;
      background-color: #989ba938;
      margin: 0 15px 0 0;
    }
  }
}

.header {
  .header-inner {
    position: fixed;
    z-index: 1;
    top: 0px;
    background-color: white;
    width: calc(100% - 200px);
  }
}