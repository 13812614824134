.similar-products-widget {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.product-card {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 10px;
}

.product-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.product-info h3 {
    font-size: 14px;
    color: #333;
    margin: 0;
}

.product-info p {
    font-size: 12px;
    color: #666;
    margin: 0;
} 