.selected-reason-widget {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    div {
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        padding: 16px 24px;
        max-width: 80%;
        text-align: center;
        color: #333;
        font-size: 15px;
        line-height: 1.5;
        border: 1px solid red;
        
        p {
            margin: 0;
        }

        strong {
            color: #0066FF;
            font-weight: 600;
        }
    }
}
