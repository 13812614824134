@tailwind base;
@tailwind components;
@tailwind utilities;

button {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
    color: #3F3F3F;
}

body {
    background: #EFF6F8;
}


// input[type="file"]::-webkit-file-upload-button {
//     -webkit-appearance: none;
//     display: none;
// }

.thumbnail-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin-top: 10px;
  
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
