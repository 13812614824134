.place-order-widget {
    margin: 10px 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    p {
        margin-bottom: 20px;
        font-size: 14px;
        color: #333;
    }
}

.button-group {
    display: flex;
    justify-content: space-around;
}

.primary-button {
    padding: 10px 20px;
    background-color: #d32f2f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #b71c1c;
    }
}

.secondary-button {
    padding: 10px 20px;
    background-color: transparent;
    color: #d32f2f;
    border: 2px solid #d32f2f;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: #d32f2f;
        color: #fff;
    }
} 
