.form-field {
  label {
    display: block;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #303030;
  }
  .input-field {
    position: relative;
    min-width: 300px;
    border: 1px solid #858585;
    border-radius: 4px;
    background-color: #fff;
    .search-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: #278bed;
      height: 30px;
      width: 36px;
      border-radius: 0px 2px 2px 0px;
      svg {
        color: #fff;
      }
    }
    .search-icon-left {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 30px;
      width: 36px;
      border-radius: 0px 2px 2px 0px;
    }
    input {
      border: none;
      width: calc(100% - 40px);
    }
    .if-search-left {
      padding-left: 30px;
      width: 100%;
    }
  }

  input,
  select {
    border: 1px solid #858585;
    background-color: #ffffff;
    font-size: 12px;
    padding: 5px;
    outline: none;
    height: 30px;
    border-radius: 4px;
    width: fit-content;
  }
  &.form-field-x {
    // label {
    //     width: 20%;
    // }
    input {
      width: calc(100% - 40px);
    }
  }
}
.bg-sky-50 {
  background-color: #eff6f8 !important;
}

.campaign-form-field {
  select {
    height: 36px !important;
    border: 1px solid #d1d5db;
    background-color: #ffffff;
    font-size: 12px;
    padding: 5px;
    outline: none;
    border-radius: 4px;
  }
}

.select-form-field {
  select {
    width: 100%;
    border: 1px solid #d1d5db;
    background-color: #ffffff;
    border-left: none;
    height: 36px;
    font-size: 12px;
    padding-left: 10px;
    color: #9ca3af;
  }
}
.full-width {
  select {
    width: 100%;
    border: 1px solid #d1d5db;
  }
}
.select_full-width {
  select {
    width: 100%;
    height: 34px;
    border: 1px solid #d1d5db;
  }
}
