.test-container {
    padding: 20px;
}

.widget-types {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 10px;
}

.widget-type {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
}

.widget-type:hover {
    background-color: #e0e0e0;
}

.widgets {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.widgets .widget {
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f5f5f5;
}

.widget {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
}

.widget h3 {
    margin-top: 0;
}

.widget strong {
    display: block;
    margin-top: 10px;
}

.widget pre {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 3px;
    overflow-x: auto;
}

.chips-widget-section {
    margin-bottom: 30px;
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 5px;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.chip {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #e0e0e0;
}

.widget-actions {
    margin-bottom: 10px;
}

.add-widget-btn, .edit-widget-btn {
    margin-right: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.add-widget-btn:hover, .edit-widget-btn:hover {
    background-color: #0056b3;
}

.associated-widgets .widget {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f5f5f5;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

textarea {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-family: monospace;
}

button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.chips-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap into new rows */
    padding: 10px;
    gap: 10px;       /* Space between each chip card */
}

.chip-card {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    min-width: 150px;  /* Ensures a minimum width for each card */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}