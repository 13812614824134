// .popup-container {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .popup {
//     background-color: white;
//     padding: 20px;
//     max-width: 80%;
//     max-height: 80%;
//     overflow-y: auto;

//     button {
//       font-size: 1.2rem;
//       margin-bottom: 10px;
//       padding: 5px 10px;
//       border: none;
//       background-color: #ccc;
//       cursor: pointer;
//     }
//   }
// }

.json-popup{
  width: 60% !important;
  height: 80vh;
  .popup-content-inner{
    width: 100%;
    overflow: scroll;
    height: 100%;
    button{
      svg{
        font-size: 24px;
      }
    }
  }
}