.mainWrapper {
  .sidenav-wrapper {
    width: 200px;
  }
  .right-container {
    width: calc(100% - 200px);
    position: relative;

    .header-container {
      width: 100%;
    }

    .body-container {
      width: 100%;
      position: absolute;
      top: 60px;
      left: 0px;
      z-index: -1;
    }
  }
}

.pagelist-filter {
  background-color: #ffffff;

  .form-field {
    margin-right: 20px;
  }
  .right-create-btn{
    position: absolute;
    right: 15px;
    top: 30px;
  }
}

.pagelist-tbl {
  table {
    thead {
      background-color: #343434;

      tr {
        th {
          color: #fff;
          font-size: 12px;
          padding: 10px 15px;
        }
      }
    }

    tbody {
      tr {

        // position: relative;
        td {
          font-size: 12px;
          color: #3f3f3f;
          padding: 10px 15px;

          .layout-name {
            font-size: 12px;
          }

          .layout-id {
            font-size: 10px;
            color: #959595;
          }

          &.action {
            .action-inner {
              // border: 1px solid red;
              // background-color: red;
              svg {
                font-size: 16px;
              }

              &:nth-child(2n) {
                margin-left: 15px;
              }

              .action-name {
                display: none;
              }
              &.action-inner:hover {
                position: relative;
                .action-name {
                  position: absolute;
                  top: 20px;
                  right: 0;
                  display: block !important;
                  background-color: #ffffff;
                  border-radius: 4px;
                  box-shadow: 0px 5px 11px #00000029;
                  z-index: 1;
                }
              }
            }

            // position: relative;
            // .action-tooltip {
            //   position: absolute;
            //   padding: 10px 0px;
            //   top: 35px;
            //   min-width: 120px;
            //   left: -80px;
            //   background-color: #ffffff;
            //   border-radius: 4px;
            //   box-shadow: 0px 5px 11px #00000029;
            //   z-index: 1;
            //   > div {
            //     padding: 10px 15px;
            //     align-items: center;
            //     &:hover, :active {
            //       background-color: #eff6f8;
            //     }
            //   }
            // }
          }
        }
        &:nth-child(2n) {
          background-color: #f7f7f7;
        }

        // .action-tooltip {
        //   position: absolute;
        //   padding: 10px 0px;
        //   top: 35px;
        //   right: 85px;
        //   background-color: #ffffff;
        //   border-radius: 4px;
        //   box-shadow: 0px 5px 11px #00000029;
        //   z-index: 1;
        //   > div {
        //     padding: 10px 15px;
        //     align-items: center;
        //     &:hover, :active {
        //       background-color: #eff6f8;
        //     }
        //   }
        // }
      }
    }
  }
}