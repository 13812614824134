.login-container {
    .login-header {
        box-shadow: 0px 2px 6px #0000000A;
        min-height: 70px;

        img {
            width: 70px;
        }
    }

    .login-body {
        background-color: #F7F7F7;
        height: calc(100vh - 70px);

        .login-inner {
            box-shadow: 0px 3px 30px #00000014;

            h2 {}

            button {
                box-shadow: 0px 3px 8px #00000029;

                img {
                    width: 18px;
                }
            }

            p {
                color: #8C98A9;
            }
        }
    }
}