.pagelist-pagination{
    bottom: 0px;
    width: calc(100% - 200px);
    right: 0px;
    .pagination-inner{
        .display-txt{
            font-size: 10px;
            color: #A3A6B4;
        }
        .pagination-list{
            .page-number{
                border: 1px solid #D7DAE2;
                border-right: none;
                width: 32px;
                height: 24px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                font-size: 12px;
                p{
                    color: #808495;
                }
                &.active{
                    background-color: #3B86FF;
                    border: 1px solid #3b86ff;
                    p{
                        color: #fff;
                    }
                }
                &:first-child{
                    border-radius: 4px 0 0 4px;
                }
                &:last-child{
                    border-radius: 0px 4px 4px 0;
                    border-right: 1px solid #D7DAE2;
                }
            }
        }
    }
}