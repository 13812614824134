.search-component{
    // height: 100vh;
    .search-component-inner{
        .search-txt{
            font-size: 12px;
            font-weight: 500;
        }
        .keyword-name{
            h4{
                font-size: 16px;
            }
            p{
                color: #A3A6B4;
                font-size: 12px;
            }
        }
        .btm-fixed-strip{
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: calc(100% - 200px);
            .btm-fixed-strip-inner{
                border: 1px solid #EAEAEA;
                p{
                    font-size: 16px;
                    color: #3F3F3F;
                }
            }
        }
    }
}