.ml {
  margin-left: 48.5rem;
}


.bottom-border {
  border: none;
  border-bottom: 1px solid rgb(172, 165, 165);
}

.dotted-border {
  border: none;
  border-bottom: 1px dotted rgb(172, 165, 165);
  background-color: white;
  width: 100%;

}

.container {
  background-color: white;
  color: black
}

.main {
  width: 70%;

}

.header {
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  margin: 20px 10px 10px 10px;
  padding: 11px;
  width: 30%;
  height: 400px;
  background-color: white;
}

.thumbnail {
  width: 100%;
  height: 50%
}