.Component-creation {
  // background-color: #eff6f8;

  .component-creation-header {
    text-align: center;

    h2 {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 40%;
    }

    .header-right-sec {
      .status {
        margin-right: 20px;
      }

      button {
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
        display: flex;

        svg {
          font-size: 20px;
        }

        &.status-btn {
          width: 40px;

          svg {
            font-size: 32px;
          }
        }
      }
    }

  }

  // .sub-component-creation-inner{
  //   .header-right-sec{
  //     button{
  //       height: 30px;
  //       width: 30px;
  //       align-items: center;
  //       justify-content: center;
  //       display: flex;
  //       svg{
  //         font-size: 20px;
  //       }
  //     }
  //   }
  // }

  .sub-componrnt-creation {
    margin: 0 0 15px 15px;

    .sub-componrnt-creation-inner {
      width: 90%;
      margin-left: 10%;
    }
  }

  // use this classname in input if input is filled
  .input-filled {
    background-color: #f4f4f4;
  }
}

.action-field {
  width: 30px;
  height: 30px;
  background-color: rgba(255, 0, 0, 0.082);
  border-radius: 4px;

  svg {
    color: #d9322e;
  }
}

.inactive {
  color: #d9322e;
}

.active {
  color: green;
}


.Component-creation-inner {
  width: calc(33% - 30px);
}





.act-btn {
  .action-name {
    display: none;
  }

  &.act-btn:hover {
    position: relative;

    .action-name {
      position: absolute;
      top: 25px;
      right: 0;
      display: block !important;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 5px 11px #00000029;
      z-index: 1;
    }
  }
}

.toggle-cont {
  .action-name {
    display: none;
  }
  &.toggle-cont>.text-red-600:hover {
    position: relative;
    .action-name {
      position: absolute;
      top: 25px;
      right: 0;
      display: block !important;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 5px 11px #00000029;
      z-index: 1;
    }
  }
  .text-red-600:hover .action-name{
    display: block !important;
  }
}