.leftsidewrap {
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  // background: #43425d;
  // z-index: 1;
  .moglogo {
    padding: 22px 15px 15px;
    position: fixed;
    z-index: 3;
    background: #43425d;
    a {
      img {
        width: 70px;
      }
    }
  }
  
  .leftsidemenu {
    padding: 0px;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #43425d;
    height: calc(100% - 60px);
    overflow-y: scroll;
    li {
      font-size: 14px;
      &.active,
      &:hover {
        a {
          background: #36354e;
          border-left: 5px solid #d9322e;
          padding-left: 12px !important;
        }
      }
      a {
        display: block;
        padding: 0 15px;
        color: #fff;
        border-left: 3px solid transparent;
        height: 40px;
        line-height: 40px;
        text-decoration: none;
      }
    }
  }
}
