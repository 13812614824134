@import "../../forms.scss";
.overlay {
  // background-color: #00000075;
  z-index: 3;
  width: 100%;
  height: 100vh;
  position: relative;

  .right-popup-content {
    padding: 20px 0px 20px 20px;
    position: absolute;
    // width: fit-content;
    z-index: 4;
    top: 0 !important;
    right: 0 !important;
    width: 30%;
    height: 100%;
    background-color: #f7f7f7;
    box-shadow: 0px 5px 11px #00000029;
    .popup-content-inner {
      h2 {
        color: #43425d;
        font-size: 20px;
        line-height: 40px;
      }
      p {
        color: #a3a6b4;
      }
      .form-field {
        margin-top: 15px;
        padding-right: 20px;
        input,
        select {
          width: 100%;
        }
        .action-field-container{
          input {
            width: calc(100% - 40px);
          }
          .action-field {
            width: 30px;
            height: 30px;
            background-color: rgba(255, 0, 0, 0.082);
            border-radius: 4px;
            margin-left: 10px;
            svg {
              color: #d9322e;
            }
          }
        }
      }
      .add-field {
        background-color: #3b86ff1f;
        color: #3b86ff;
        border-radius: 4px;
        font-size: 14px;
      }
    }
  }
}
.right-form-container{
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.form-saperator-line{
  background-color: #8585853d;
  height: 1px;
  width: 100%;
  margin-top: 20px;
}