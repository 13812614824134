.coupon-widget {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.coupon-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e8f5e9;
    border-radius: 8px;
    padding: 10px;
}

.deal-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.coupon-info {
    flex-grow: 1;
    margin-right: 10px;
}

.coupon-info p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.coupon-code {
    background-color: #388e3c;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
}

.copied-text {
    font-size: 10px;
    color: #666;
}

.expiry-text {
    font-size: 12px;
    color: #d32f2f;
    margin-top: 5px;
    text-align: center;
} 